import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';

import BlogPosts from '../components/blog-posts';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import NotFound from '../pages/404';

const BlogPage = ({ data }) => {
  const allPosts = data.allMarkdownRemark.edges;
  const [filteredPosts, setFilteredPosts] = useState(allPosts);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const noBlog = !allPosts || !allPosts.length;

  // Gather unique categories and add "All" option for filtering
  useEffect(() => {
    const uniqueCategories = Array.from(new Set(allPosts.flatMap(post => post.node.frontmatter.categories || [])));
    setCategories(['All', ...uniqueCategories]);
  }, [allPosts]);

  // Handle category and search filtering
  useEffect(() => {
    let filtered = allPosts;

    // Filter by category if not "All"
    if (selectedCategory !== 'All') {
      filtered = filtered.filter(post => post.node.frontmatter.categories?.includes(selectedCategory));
    }

    // Filter by search term if it exists
    if (searchTerm) {
      filtered = filtered.filter(post =>
        post.node.frontmatter.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.node.frontmatter.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.node.html?.toLowerCase().includes(searchTerm.toLowerCase())  // Optional chaining for safety
      );
    }

    setFilteredPosts(filtered);
  }, [selectedCategory, searchTerm, allPosts]);

  // Handle search term input
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle category selection
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  if (noBlog) {
    return <NotFound />;
  }

  return (
    <Layout>
      <SEO title="Blog" />
      <Header metadata={data.site.siteMetadata} />
      
      {/* Search input */}
      <div>
        <input
          type="text"
          placeholder="Search posts..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      
      {/* Category dropdown */}
      <div>
        <label htmlFor="categorySelect">Filter by category:</label>
        <select
          id="categorySelect"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          {categories.map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      
      {/* Display filtered blog posts */}
      {filteredPosts.length === 0 ? (
        <p>No posts found.</p>
      ) : (
        <BlogPosts posts={filteredPosts} />
      )}
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        medium
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            categories
          }
          html  # Ensure content is fetched for search
        }
      }
    }
  }
`;


